import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  public testimonials: any[] = [
    {
      text: "O Sistema Financeiro mudou completamente a maneira como eu lido com meu dinheiro. Agora consigo controlar minhas despesas e economizar mais!",
      author: "Ana Paula, São Paulo",
    },
    {
      text: "Nunca imaginei que poderia ser tão fácil controlar minhas finanças. O Sistema Financeiro me ajudou a ter uma visão clara de onde meu dinheiro está indo.",
      author: "Lucas Silva, Rio de Janeiro",
    },
    {
      text: "Com apenas alguns cliques, o Sistema Financeiro me ajuda a manter o meu controle financeiro.",
      author: "João Santos, Belo Horizonte",
    },
    {
      text: "Estava cansado de me preocupar com minhas finanças, mas graças ao Sistema Financeiro, agora posso desfrutar de uma vida financeira mais tranquila.",
      author: "Roberto Garcia, Curitiba",
    },
    {
      text: "O recurso de planejamento financeiro do Sistema Financeiro é realmente útil. Agora posso planejar minhas despesas e poupanças com antecedência.",
      author: "Fernanda Alves, Florianópolis",
    },
    {
      text: "O Sistema Financeiro é uma ferramenta indispensável para quem deseja ter um controle financeiro efetivo e fácil de usar.",
      author: "Tiago Souza, Porto Alegre",
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
