<div *ngIf="showOverlay">
    <div id="loader-wrapper">
      <div id="loader"></div>
  
      <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
  
    </div>
  
  </div>
  
  <div *ngIf="showOverlay == false" style="height:100%;">
    <router-outlet></router-outlet>
  </div>