import { LoginServiceService } from './../../services/login-service.service';
import { Login } from './../../shared/login';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cadastro-screen',
  templateUrl: './cadastro-screen.component.html',
  styleUrls: ['./cadastro-screen.component.css']
})
export class CadastroScreenComponent implements OnInit {

  loginForm: FormGroup = new FormGroup({});

  constructor(private loginService: LoginServiceService, private router: Router) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      password2: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      let values = this.loginForm.value;

      if(values.password != values.password2) {
        Swal.fire("Ooops...", "Senhas divergentes", 'error')

        return;
      }
      let dados = {
        'name': values.name,
        'login': values.email,
        'password': values.password
      }
      this.loginService.signUp(dados).subscribe(response => {

        Swal.fire("Sucesso", "Cadastro efetuado com sucesso!").then(a => {
          this.router.navigate(["/login"]);
        });

      }, err => {
        Swal.fire("Ooops...", err.error.message, 'error')
      });
    }

  }

  public checkError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

}
