<div class="header">
  <img src="/assets/img/nova-logo.png" alt="Logo" style="width: 200px; text-align: center;" />
  <h1 style="margin-top: 15px">Sistema Financeiro</h1>
  <h2 style="margin-top: 15px">
    Conquiste a liberdade financeira: domine seus gastos com nosso sistema de
    controle financeiro
  </h2>
  <div style="margin-top: 15px">
    <button class="btn" routerLink="/cadastre-se">Cadastre-se</button>
    <button class="btn" routerLink="/sistema">Entre</button>
  </div>
</div>
<div class="content">
  <h1>
    Sistema Financeiro - Gerencie suas finanças pessoais de forma fácil e
    eficiente.
  </h1>
  <p>
    Gerencie suas finanças pessoais de forma fácil e eficiente com o Sistema
    Financeiro. Nossa plataforma online de gerenciamento financeiro foi
    desenvolvida especialmente para usuários leigos, permitindo que você tenha
    uma visão clara e organizada de suas finanças pessoais.
  </p>
  <p>
    Com nosso sistema, você pode visualizar as principais despesas do mês, o
    saldo recebido e o lucro ou dívida em tempo real, tudo de forma pessoal e
    privada. Além disso, temos menus específicos para o gerenciamento de
    recebimentos e despesas, o que torna o processo de controle ainda mais fácil
    e personalizado.
  </p>
  <p>
    O Sistema Financeiro oferece uma experiência simples e intuitiva para o
    usuário, permitindo que você acompanhe suas finanças pessoais de forma
    tranquila e sem complicações. Com nossas ferramentas, você poderá controlar
    seus gastos e receitas, estabelecer metas financeiras pessoais e realizar um
    planejamento estratégico para alcançar seus objetivos pessoais.
  </p>
  <p>
    Não deixe suas finanças pessoais saírem do controle. Comece a usar o
    Sistema Financeiro hoje mesmo e tenha acesso a informações precisas e
    atualizadas sobre suas finanças pessoais em um único lugar, de forma segura
    e privada.
  </p>
</div>

<div class="bg content">
  <h1>Dúvidas frequentes</h1>
  <mat-expansion-panel style="margin-top: 10px">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Quem pode utilizar o Sistema Financeiro?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>
      Qualquer pessoa que queira ter um melhor controle de suas finanças
      pessoais pode utilizar o Sistema Financeiro. Não é necessário nenhum
      conhecimento específico em finanças, basta ter acesso à internet para
      utilizar a plataforma.
    </p>
  </mat-expansion-panel>

  <mat-expansion-panel style="margin-top: 10px">
    <mat-expansion-panel-header>
      <mat-panel-title> O Sistema Financeiro é pago? </mat-panel-title>
    </mat-expansion-panel-header>
    <p>Não, o Sistema Financeiro é uma plataforma totalmente gratuita.</p>
  </mat-expansion-panel>

  <mat-expansion-panel style="margin-top: 10px">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Quais tipos de despesas e recebimentos podem ser gerenciados no Sistema
        Financeiro?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>
      O usuário pode gerenciar todas as suas despesas e recebimentos, desde as
      contas básicas do dia a dia, como água, luz e telefone, até despesas mais
      específicas, como compras parceladas, pagamentos de empréstimos,
      investimentos, entre outros.
    </p>
  </mat-expansion-panel>

  <mat-expansion-panel style="margin-top: 10px">
    <mat-expansion-panel-header>
      <mat-panel-title>
        É seguro inserir minhas informações financeiras no Sistema Financeiro?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>
      Sim, o Sistema Financeiro utiliza tecnologia avançada de criptografia para
      proteger todas as informações inseridas pelos usuários. Além disso, a
      plataforma é atualizada constantemente para garantir a segurança dos
      dados.
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel style="margin-top: 10px">
    <mat-expansion-panel-header>
      <mat-panel-title>
        O Sistema Financeiro pode ser acessado por dispositivos móveis?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>
      Sim, o Sistema Financeiro é totalmente responsivo e pode ser acessado de
      qualquer dispositivo com acesso à internet, incluindo smartphones e
      tablets.
    </p>
  </mat-expansion-panel>
</div>
<div class="content">
  <h1 style="margin-top: 15px">Depoimentos</h1>
  <div fxLayout="row wrap" fxLayout.xs="column"  fxLayoutAlign="space-between center">
    <mat-card *ngFor="let testimonial of testimonials" fxFlex="33%"  style="height:200px; margin-bottom:5px;">
      <mat-card-content fxFlex="1 1 auto">
        <h4>{{ testimonial.text }}</h4>
        <p>{{ testimonial.author }}</p>
      </mat-card-content>
    </mat-card>
  </div>
  
</div>
