import { LoginServiceService } from "./../../services/login-service.service";
import { Login } from "./../../shared/login";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-screen",
  templateUrl: "./login-screen.component.html",
  styleUrls: ["./login-screen.component.css"],
})
export class LoginScreenComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({});

  constructor(
    private loginService: LoginServiceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      let values = this.loginForm.value;
      let dados = {
        login: values.email,
        password: values.password,
      };
      this.loginService.signIn(dados).subscribe(
        (response) => {
          localStorage.setItem("UserObject", JSON.stringify(response.user));
          localStorage.setItem("userToken", response.token);

          this.router.navigate(["/sistema/home"]);
        },
        (err) => Swal.fire("Ooops...", "Email e/ou senha incorretos", "error")
      );
    }
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };
}
